webix.i18n.pivot = webix.extend((webix.i18n.pivot||{}),{
	apply: "Apply",
	bar: "Bar",
	cancel: "Cancel",
	chart: "Chart",
	chartType: "Chart type",
	columns: "Columns",
	count: "count",
	date: "date",
	datepicker: "datepicker",
	fields: "Fields",
	filters: "Filters",
	groupBy: "Group By",
	line: "Line",
	logScale: "Logarithmic scale",
	max: "max",
	min: "min",
	multicombo: "multi-select",
	operationNotDefined: "Operation is not defined",
	layoutIncorrect: "pivotLayout should be an Array instance",
	pivotMessage: "Click to configure",
	popupHeader: "Pivot Settings",
	radar: "Radar",
	radarArea: "Area Radar",
	rows: "Rows",
	select: "select",
	settings: "Settings",
	stackedBar: "Stacked Bar",
	sum: "sum",
	text: "text",
	total: "Total",
	values: "Values",
	valuesNotDefined: "Values or Group field are not defined",
	windowTitle: "Pivot Configuration",
	windowMessage: "move fields here"
});



